<template>
    <div>
        <CompTable ref="table" tableApi="/gateway/org/pc/grassrootsOrg/selectTemplateList?rootType=2" :columns="columns" title="架构线">
            <template #operate>
                <Button icon="ios-add" type="primary" @click="onDisplayForm()">新增</Button>
            </template>
        </CompTable>

        <CompModal ref="modal" class="communitymobilization-definition" title="架构线搭建" :onSuccess="onSubmit">
            <div class="architecture-name">
                <p class="title">架构线名称</p>
                <Input v-model="rootOrgName" type="text" placeholder="填写架构名称，如：志愿者队伍" style="width: 300px;" />
            </div>
            <div class="attr-box">
                <div class="name-box">
                    <div class="name">层级</div>
                    <div class="name">名称</div>
                    <div class="name value-box">关联数据</div>
                    <div class="operate-add" @click="onAddItem">
                        <Icon class="icon" type="md-add" color="#fff" />
                    </div>
                </div>
                <div class="item-box" v-for="(item, idx) in list" :key="item.item_id">
                    <div class="item-form">
                        <div class="item">
                            <div class="module input">{{ idx + 1 }}</div>
                        </div>
                        <div class="item">
                            <Input class="module" v-model="item.name" type="text" placeholder="请输入名称" />
                        </div>
                        <div class="item value-box">
                            <Select v-model="item.value" class="module" filterable>
                                <Option v-for="opt in templateForms" :key="opt.templateId" :value="opt.templateId">{{ opt.appName }}</Option>
                            </Select>
                        </div>
                    </div>
                    <div class="operate">
                        <div class="operate-item remove" @click="onRemoveItem(idx)">
                            <Icon class="icon" type="md-remove" color="#fff" />
                        </div>
                    </div>
                </div>
            </div>
        </CompModal>
    </div>
</template>

<script>
import CompTable from "../jointly/components/comp-table"
import CompModal from "../residentdatabase/components/CompModal.vue"
import Request from "../jointly/utils/request"
import Route from "./entity/Route"

export default {
    components: {
        CompTable,
        CompModal,
    },

    data() {
        return {
            columns: [
                {
                    title: "架构线名称",
                    key: "rootOrgName",
                },
                {
                    title: "创建时间",
                    width: 200,
                    render: (h, params) => this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss"),
                },
                {
                    title: "启用状态",
                    key: "status",
                    minWidth: 120,
                    align: "center",
                    render: (h, params) => {
                        return h("i-switch", {
                            props: {
                                value: params.row.status === "2" ? true : false,
                            },
                            on: {
                                "on-change": res => {
                                    // 延迟 500ms 执行，避免导致动画卡顿
                                    setTimeout(() => {
                                        Request.post(
                                            "/gateway/org/pc/grassrootsOrg/updateStatus",
                                            {
                                                rootOrgId: params.row.rootOrgId,
                                                status: res ? "2" : "1",
                                            },
                                            {
                                                json: true,
                                            }
                                        ).then(v => {
                                            if (!v) {
                                                // 失败时取反值
                                                params.row.status = res ? "1" : "2"
                                            }
                                        })
                                    }, 500)
                                },
                            },
                        })
                    },
                },
                {
                    float: "right",
                    title: "操作",
                    width: 180,
                    maxWidth: 180,
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.onDisplayForm(params.row)
                                        },
                                    },
                                },
                                "编辑"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "error",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$Modal.confirm({
                                                title: "提示",
                                                content: "当前删除操作将不可恢复，请谨慎操作，是否继续？",
                                                onOk: () => {
                                                    this.$post(
                                                        "/gateway/org/pc/grassrootsOrg/deleteOrgNodeForce",
                                                        {
                                                            rootId: params.row.rootOrgId,
                                                            rootOrgId: params.row.rootOrgId,
                                                        },
                                                        {
                                                            "Content-Type": "application/json",
                                                        }
                                                    ).then(res => {
                                                        if (res.code == 200) {
                                                            this.$Message.success({
                                                                content: "删除成功",
                                                                background: true,
                                                            })
                                                            // 刷新表格
                                                            this.$refs.table.refresh()
                                                        } else {
                                                            this.$Message.error({
                                                                content: res.desc || "删除失败",
                                                                background: true,
                                                            })
                                                        }
                                                    })
                                                },
                                            })
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
            ],
            rootOrgName: null,
            list: [
                {
                    item_id: this.$core.randomString(),
                },
            ],
            templateForms: [],
        }
    },

    created() {
        this.onLoadForms()
    },

    methods: {
        onLoadForms() {
            Request.get("/gateway/api/fastDev/AppTemplate/findByBizCategory?bizCategory=JGX").then(res => {
                this.templateForms = res
            })
        },

        onAddItem() {
            this.list.push({
                item_id: this.$core.randomString(),
            })
        },

        onRemoveItem(idx) {
            this.list.splice(idx, 1)

            // 如果只剩下一个，则添加一个
            if (this.list.length === 0) {
                this.list.push({
                    item_id: this.$core.randomString(),
                })
            }
        },

        onDisplayForm(data) {
            this.rootOrgId = data?.rootOrgId
            this.rootOrgName = data?.rootOrgName

            const cfg = data?.config || {}

            this.list = cfg.depthJson?.map(v => ({
                item_id: this.$core.randomString(),
                name: v.rootOrgName,
                value: v.appId,
            })) || [
                {
                    item_id: this.$core.randomString(),
                },
            ]

            this.$refs.modal.display()
        },

        onSubmit() {
            const l = this.list

            const data = {
                rootOrgId: this.rootOrgId,
                rootOrgName: this.rootOrgName,
                communityCode: parent.vue.loginInfo.userinfo.communityCode,
                rootType: 2, // 架构类型；1：组织架构；2：人员架构
                orgDepth: l.length,
                depthJson: l.map((v, i) => ({
                    index: i + 1,
                    rootOrgName: v.name,
                    appId: v.value,
                })),
            }

            Request.post("/gateway/org/pc/grassrootsOrg/v2/saveTemplate", data, {
                json: true,
            }).then(() => {
                this.$Message.success("提交成功！")
                // 关闭
                this.$refs.modal.close()
                // 刷新表格
                this.$refs.table.refresh()
            })
        },
    },
}
</script>

<style lang="less">
.communitymobilization-definition {
    .architecture-name {
        display: flex;
        align-items: center;
        margin: 20px;

        .title {
            margin-right: 10px;
        }
    }

    .attr-box {
        margin: 0 10px;
        padding: 20px 0;
        overflow-x: auto;
        border-top: 1px solid #f3f3f3;

        .name-box {
            display: flex;
            align-items: flex-end;
            margin-bottom: 10px;

            .name {
                padding: 0 10px;
                width: 200px;
                margin: 0 10px;
            }

            .value-box {
                width: 300px;
            }

            .operate-add {
                cursor: pointer;
                margin: 0 10px;
                padding: 0 15px;
                font-size: 20px;
                border-radius: 4px;
                background: #6fc3f8;
            }
        }

        .item-box {
            margin-bottom: 10px;
            display: flex;

            .item-form {
                width: 760px;
                display: flex;
                flex-shrink: 0;
            }

            .item {
                margin: 0 10px;

                .module {
                    width: 200px;

                    &.input {
                        height: 32px;
                        line-height: 1.5;
                        padding: 4px 7px;
                        font-size: 14px;
                        border: 1px solid #dcdee2;
                        border-radius: 4px;
                        color: #515a6e;
                        background-color: #fff;
                    }
                }

                .range-box {
                    width: 200px;

                    .range-item {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .range {
                            width: 90px;
                        }
                    }
                }

                &.value-box {
                    width: 300px;

                    .module {
                        width: 300px;
                    }

                    .range-box {
                        width: 300px;

                        .range-item {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .range {
                                width: 140px;
                            }
                        }
                    }
                }
            }

            .operate {
                margin: 0 10px;

                .operate-item {
                    cursor: pointer;
                    padding: 0 15px;
                    font-size: 20px;
                    border-radius: 4px;

                    &.add {
                        background: #6fc3f8;
                    }

                    &.remove {
                        background: #f88662;
                    }
                }
            }
        }
    }
}
</style>
